// App.js

import React, { useState } from 'react';
import './aol.css'; // Assuming you have some CSS for styling
import Swal from 'sweetalert2';

function NO2Prediction() {
  const [prediction, setPrediction] = useState(null);
  const [formData, setFormData] = useState({
    O3: '',
    PM10: '',
    'PM2.5': '',
    'Wind-Speed (U)': '',
    'Wind-Speed (V)': '',
    'Dewpoint Temp': '',
    Temp: '',
    'Vegitation (High)': '',
    'Vegitation (Low)': '',
    'Soil Temp': '',
    'Total Percipitation': '',
    'Relative Humidity': ''
  });
  const handleRandom = (e) => {
    e.preventDefault();
    const randomValue = (min, max) => (Math.random() * (max - min) + min).toFixed(2);
    
    setFormData({
      O3: randomValue(0, 100),
      PM10: randomValue(0, 100),
      'PM2.5': randomValue(0, 100),
      'Wind-Speed (U)': randomValue(0, 10),
      'Wind-Speed (V)': randomValue(0, 10),
      'Dewpoint Temp': randomValue(0, 30),
      Temp: randomValue(0, 40),
      'Vegitation (High)': randomValue(0, 100),
      'Vegitation (Low)': randomValue(0, 100),
      'Soil Temp': randomValue(0, 30),
      'Total Percipitation': randomValue(0, 50),
      'Relative Humidity': randomValue(0, 100),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show loading dialog
    Swal.fire({
      title: 'Predicting...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await fetch('https://api-python.portfolio-harura.cloud/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setPrediction(data);

      // Close loading dialog and show result
      Swal.fire({
        icon: 'info',
        title: 'Prediction Result',
        html: `<p>NO2 Prediction: ${data['NO2 Prediction']}</p><p>Description: ${data['Description']}</p>`
      });

    } catch (error) {
      console.error('Error:', error);

      // Show error message if prediction fails
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to predict NO2 level. Please try again.',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleIngfo = (e) => {
    e.preventDefault();
    const infoData = [
      { variable: 'NO2', description: 'Konsentrasi nitrogen dioksida di atmosfer (μg/m3).' },
      { variable: 'O3', description: 'Konsentrasi ozon di atmosfer (μg/m3).' },
      { variable: 'PM10', description: 'Konsentrasi aerosol atmosfer dengan diameter partikel maksimal 10 mikrometer (μg/m3).' },
      { variable: 'PM2.5', description: 'Konsentrasi aerosol atmosfer dengan diameter partikel maksimal 2.5 mikrometer (μg/m3).' },
      { variable: 'Wind-Speed (U)', description: 'Kecepatan angin sejajar dengan sumbu x (bujur) dalam meter per detik (m/s).' },
      { variable: 'Wind-Speed (V)', description: 'Kecepatan angin sejajar dengan sumbu y (lintang) dalam meter per detik (m/s).' },
      { variable: 'Dewpoint Temp', description: 'Suhu titik embun, suhu di mana udara mencapai kejenuhan uap air.' },
      { variable: 'Temp', description: 'Suhu udara.' },
      { variable: 'Vegitation (High)', description: 'Tutupan tanaman tingkat tinggi.' },
      { variable: 'Vegitation (Low)', description: 'Tutupan tanaman tingkat rendah.' },
      { variable: 'Soil Temp', description: 'Suhu rata-rata tanah.' },
      { variable: 'Total Percipitation', description: 'Jumlah curah hujan yang mencapai permukaan tanah (mm).' },
      { variable: 'Relative Humidity', description: 'Kelembaban relatif, persentase uap air di udara dibandingkan dengan kapasitas maksimumnya.' },
    ];

    const tableContent = `
      <table style="width:100%; text-align: left; border-collapse: collapse;">
        <tr>
          <th style="border: 1px solid black; padding: 5px; font-size: 12px;">Variabel</th>
          <th style="border: 1px solid black; padding: 5px; font-size: 12px;">Penjelasan</th>
        </tr>
        ${infoData.map(item => `
          <tr>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px;">${item.variable}</td>
            <td style="border: 1px solid black; padding: 5px; font-size: 12px;">${item.description}</td>
          </tr>
        `).join('')}
      </table>
    `;

    Swal.fire({
      title: 'Informasi Variabel',
      html: tableContent,
      width: '700px'
    });
  };
  return (
    <div className="App-aol">
      <header className="App-header-aol">
        <h1 className='h1-aol'>NO2 Prediction</h1>
        <form onSubmit={handleSubmit} id='form-aol'>
          <div className='form-aol'>
          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="O3" name="O3" value={formData.O3} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="O3">O3:</label>
          </div>
          
          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="PM10" name="PM10" value={formData['PM10']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="PM10">PM10:</label>
          </div>

          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="PM2.5" name="PM2.5" value={formData['PM2.5']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="PM2.5">PM2.5:</label>
          </div>

          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Wind-U" name="Wind-Speed (U)" value={formData['Wind-Speed (U)']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Wind-U">Wind-Speed (U):</label>
          </div>

          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Wind-V" name="Wind-Speed (V)" value={formData['Wind-Speed (V)']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Wind-V">Wind-Speed (V):</label>
          </div>

          
          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Dewpoint-Temp" name="Dewpoint Temp" value={formData['Dewpoint Temp']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Dewpoint-Temp">Dewpoint Temp:</label>
          </div>
          
          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Temp" name="Temp" value={formData.Temp} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Temp">Temp:</label>
          </div>
          
          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Veg-High" name="Vegitation (High)" value={formData['Vegitation (High)']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Veg-High">Vegetation (High):</label>
          </div>

          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Veg-Low" name="Vegitation (Low)" value={formData['Vegitation (Low)']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Veg-Low">Vegetation (Low):</label>
          </div>

          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Soil-Temp" name="Soil Temp" value={formData['Soil Temp']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Soil-Temp">Soil Temp:</label>
            </div>
          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Total-Percip" name="Total Percipitation" value={formData['Total Percipitation']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Total-Percip">Total Precipitation:</label>
          </div>
          <div className='input-group-aol'>
            <input className='input-aol' type="text" id="Rel-Humidity" name="Relative Humidity" value={formData['Relative Humidity']} onChange={handleInputChange} required />
            <label className='label-aol' htmlFor="Relative-Humidity">Relative Humidity:</label>
           </div>
           </div>
          <div className='button-aol'>
            <button id='button-aol' onClick={handleIngfo}>Info</button>
            <button id='button-aol' onClick={handleRandom}>Random</button>
            <button id='button-aol' type="submit">Predict</button>
          </div>
        </form>

      </header>
    </div>
  );
}

export default NO2Prediction;
