import React from 'react';

const Contact = () => {
  // const whatsappLink = `https://wa.me/6285186892002?text=Name:%20${encodeURIComponent(name)}%0AEmail:%20${encodeURIComponent(email)}%0ASubject:%20${encodeURIComponent(subject)}%0AMessage:%20${encodeURIComponent(message)}`;
  return (
    <div id="contact" className="section">
      <div className="contact-container">
        <h2>Contact Me</h2>
        <form className="contact-form" action="https://formspree.io/f/meqbnekj" method="POST">
          <div className="header-form">
            <div className="form-group">
              <label className='label' htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label className='label' htmlFor="email">Email:</label>
              <input type="text" id="email" name="email" required />
            </div>
          </div>
          <div className="form-group">
            <label className='label' htmlFor="subject">Subject:</label>
            <input type="text" id="subject" name="subject" required />
          </div>
          <div className="form-group">
            <label className='label' htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="5" required></textarea>
          </div>
          <button type="submit" className="submit-button">Send Message</button>
        </form>
        <div className="contact-info">
          <h3>Other Contact Information</h3>
          <div className="social-links">
            <a href="https://www.facebook.com/ra.cndk/" target="_blank" rel="noopener noreferrer">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
            <a href="https://www.instagram.com/ra.cndk?igsh=MXhhN2VwaWp4NzVvYg%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
            <a href="https://www.linkedin.com/in/harura-cendekia/" target="_blank" rel="noopener noreferrer">
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
            <a href="https://wa.me/6285186892002" target="__blank">
              <ion-icon name="logo-whatsapp"></ion-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
    