// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, Navigation  } from 'swiper/modules'; 
import { useRef } from 'react'; // Import useRef from React


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../css/portfolio.css';
const projects = [
  {
    image: '../Asset/htdapp.png',
    title: 'HTD App',
    summary: 'HTD App adalah sebuah aplikasi web yang dirancang untuk manajemen data pegawai dan proyek magang di PLN. Aplikasi ini dibangun dengan menggunakan teknologi Python dan framework Django untuk memastikan kehandalan dan efisiensi dalam pengelolaan data.',
    link: 'https://uid-jaya.portfolio-harura.cloud/',
    languages: ['logo-html5', 'logo-css3', 'logo-javascript']
  },
  {
    image: "../Asset/No2predict.png",
    title: "NO2 Prediction",
    summary: "Proyek ini mengembangkan model machine learning untuk memprediksi konsentrasi NO2 di udara berdasarkan berbagai faktor lingkungan seperti suhu, kelembaban, dan kecepatan angin. Dengan menggunakan algoritma RandomForestRegressor.",
    link: "/NO2Prediction",
    languages: ["logo-python", "logo-html5", "logo-css3", "logo-javascript"]
  },
  {
    image: "../Asset/samurai.png",
    title: "Samurai",
    summary: "Proyek ini mengembangkan model machine learning untuk memprediksi konsentrasi NO2 di udara berdasarkan berbagai faktor lingkungan seperti suhu, kelembaban, dan kecepatan angin. Dengan menggunakan algoritma RandomForestRegressor.",
    link: "/NO2Prediction",
    languages: ["logo-python", "logo-html5", "logo-css3", "logo-javascript"]
  },
  {
    image: '../Asset/htdapp.png',
    title: 'HTD App',
    summary: 'HTD App adalah sebuah aplikasi web yang dirancang untuk manajemen data pegawai dan proyek magang di PLN. Aplikasi ini dibangun dengan menggunakan teknologi Python dan framework Django untuk memastikan kehandalan dan efisiensi dalam pengelolaan data.',
    link: 'https://uid-jaya.portfolio-harura.cloud/',
    languages: ['logo-html5', 'logo-css3', 'logo-javascript']
  },
  {
    image: "../Asset/No2predict.png",
    title: "NO2 Prediction",
    summary: "Proyek ini mengembangkan model machine learning untuk memprediksi konsentrasi NO2 di udara berdasarkan berbagai faktor lingkungan seperti suhu, kelembaban, dan kecepatan angin. Dengan menggunakan algoritma RandomForestRegressor.",
    link: "/NO2Prediction",
    languages: ["logo-python", "logo-html5", "logo-css3", "logo-javascript"]
  },
  
  // Add more projects as needed
];
const App = () => {
  const swiperRef = useRef(null); // Create a ref for Swiper

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  return (
    <section  id="portfolio"  className="card-container section">
      <img src="../public/Asset/projectbgdark.svg" alt="" />
      <div class="card__container swiper">
            <i class="ri-arrow-left-s-line" onClick={handlePrevClick}><ion-icon name="arrow-back-circle-outline"></ion-icon></i>
        <div class="card__content">
        <Swiper
        className='swiper-wrapper'
        ref={swiperRef}
        loop= {true}
        spaceBetween= {32}
        grabCursor= {true}
        slidesPerView={3}
        modules={[Pagination, Scrollbar, Navigation]}
        pagination={ {
          el: ".swiper-pagination",
          clickable: true,
          dynamicBullets: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          968: {
            slidesPerView: 3,
          },
        }}
      >
          {projects.map((item, index) => (
            <SwiperSlide key={index}>
              <article className="card__article">
                <div className="card__image">
                  <img src={item.image} alt={item.title} className="card__img" />
                  <div className="card__shadow"></div>
                </div>
                <div className="card__data">
                  <h3 className="card__name">{item.title}</h3>
                  <p className="card__description">
                    {item.summary}
                  </p>
                  <a href={item.link} className="card__button">View More</a>
                  <div className="project-languages">
                    {item.languages.map((language, langIndex) => (
                      <ion-icon key={langIndex} name={language}></ion-icon>
                    ))}
                  </div>
                </div>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
        </div>
          <i class="ri-arrow-right-s-line" onClick={handleNextClick} ><ion-icon name="arrow-forward-circle-outline" ></ion-icon></i>
        <div class="swiper-pagination"></div> 
      </div>
    </section>
  );
};

export default App;
