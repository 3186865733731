import '../App.css';
import React, { useEffect, useState } from 'react';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';

const Home = () => {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    // Function to set the active menu item on scroll
    const menuItems = document.querySelectorAll('.nav-item');

    function setActiveMenuItem() {
      const scrollPosition = window.scrollY;
    
      menuItems.forEach(item => {
        const sectionId = item.getAttribute('href').substring(1);
        const section = document.getElementById(sectionId);
    
        if (section) {
          if (
            section.offsetTop <= scrollPosition + 100 &&
            section.offsetTop + section.offsetHeight > scrollPosition + 100
          ) {
            item.classList.add('active');
          } else {
            item.classList.remove('active');
          }
        }
      });
    }

    document.addEventListener('DOMContentLoaded', setActiveMenuItem);
    window.addEventListener('scroll', setActiveMenuItem);

    return () => {
      document.removeEventListener('DOMContentLoaded', setActiveMenuItem);
      window.removeEventListener('scroll', setActiveMenuItem);
    };
  }, []);

  useEffect(() => {
    // Handle dark mode changes
    if (darkMode) {
      document.body.classList.add('dark-mode');
      document.querySelector('.navbar').classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
      document.querySelector('.navbar').classList.remove('dark-mode');
    }
  }, [darkMode]);

  useEffect(() => {
    // Handle video autoplay
    const video = document.getElementById('myVideo');
    if (video) {
      video.autoplay = true;
      video.muted = true;
      video.loop = true;
      video.play().catch(error => {
        console.error('Error attempting to play the video:', error);
      });
    }
  }, [darkMode]); // Include darkMode dependency to reset video settings

  const handleToggle = () => {
    setDarkMode(prevMode => !prevMode);
  };

  return (
    <div>
      <nav className="navbar">
        <img src="../Asset/logo.svg" alt="" />
        <a href="#home" className="nav-item">Home</a>
        <a href="#about" className="nav-item">About</a>
        <a href="#portfolio" className="nav-item">Project</a>
        <a href="#contact" className="nav-item">Contact</a>
        <div className="dark-mode-switch">
          <label className="switch">
            <input type="checkbox" checked={darkMode} onChange={handleToggle} />
            <span className="slider">
              <ion-icon name="sunny-outline" style={{ opacity:darkMode ? '0' : '1', transition:'0.6'}} className="icon sunny-icon"></ion-icon>
              <ion-icon name="moon-outline" style={{ opacity:darkMode ? '1' : '0',transition:'0.6'}} className="icon moon-icon"></ion-icon>
            </span>
          </label>
        </div>
      </nav>
      <div id="home" className="home section">
        {/* {darkMode && 
          <video id="myVideo" className="video-container">
            <source src="../Asset/bgvid2.mp4" type="video/mp4" />
          </video>
        } */}

        <div className="home-content">
          <div>
            <h3 className="typing-animation">Hello...</h3>
            <h1>I'm Harura Cendekia</h1>
            <p>Data Scientist, Full Stack Developer, & AI Enthusiast</p>
            <div className="social-buttons">
              <a href="https://wa.me/6285186892002" target="_blank" rel="noopener noreferrer">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
              <a href="https://www.linkedin.com/in/harura-cendekia/" target="_blank" rel="noopener noreferrer">
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>
              <a href="https://github.com/racndk/" target="_blank" rel="noopener noreferrer">
                <ion-icon name="logo-github"></ion-icon>
              </a>
              <a href="mailto:harura.hasanasultoni@binus.co.id">
                <ion-icon name="mail"></ion-icon>
              </a>
            </div>
          </div>
          <img src="../Asset/profile.jpeg" alt="Profile" />
        </div>
      </div>
      <div id="about" className="section">
        <div className="about-content">
          <div className="about-title">
            <img src="../Asset/bercak.svg" alt="" />
            <h2>About Me</h2>
          </div>
          <p>
            Saya Harura Cendekia, mahasiswa semester 6 dari jurusan Teknik Informatika dengan fokus dalam bidang AI di Universitas Bina Nusantara. Selain itu, saya juga memiliki keahlian dalam pengembangan web, machine learning dan deep learning.
          </p>
          <h3>Education</h3>
          <div className="education">
            <div className="education-item">
              <h4>Computer Science Undergraduate, Bina Nusantara University</h4>
              <p>2021 - Now</p>
            </div>
            <div className="education-item">
              <h4>Senior High School, SMA Muhammadiyah 11 Jakarta</h4>
              <p>2018 - 2021</p>
            </div>
          </div>
          <h3>Skills</h3>
          <ul className="skills-list">
            <li><p>HTML</p><ion-icon name="logo-html5"></ion-icon></li>
            <li><p>CSS</p><ion-icon name="logo-css3"></ion-icon></li>
            <li><p>JS</p><ion-icon name="logo-javascript"></ion-icon></li>
            <li><p>ReactJs</p><ion-icon name="logo-react"></ion-icon></li>
            <li><p>NodeJs</p><ion-icon name="logo-nodejs"></ion-icon></li>
            <li><p>Laravel</p><ion-icon name="logo-laravel"></ion-icon></li>
            <li><p>Python</p><ion-icon name="logo-python"></ion-icon></li>
          </ul>
        </div>
      </div>
        <Portfolio />
        <Contact />
    </div>
  );
};

export default Home;
